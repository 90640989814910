import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-rc.0_@babel+core@7.24.7_@opentelemetry+api@1.9.0_react-dom@19.0.0-rc-6230622a1a-2_lgrjfz7uqbrsb2mixcntdwz4iy/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["MessagesSearchBar"] */ "/vercel/path0/packages/ui/src/messages-search-bar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserSection"] */ "/vercel/path0/packages/ui/src/navbar/client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeSwitcher"] */ "/vercel/path0/packages/ui/src/theme-switcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Avatar","AvatarImage","AvatarFallback"] */ "/vercel/path0/packages/ui/src/ui/avatar.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/ui/link-button.tsx");
